@use "../util" as *;

.featured-work-section {
  .project-wrapper {
    height: 120vh;
    position: relative;
    &.fixed-on-scroll .project {
      position: fixed;
      top: 0;
    }
  }
  .project {
    width: 100%;
    height: 100vh;
    color: white;
    background-size: cover;
    background-position: center;
    position: absolute;
    left: 0;
    .project-cover {
      background-color: rgb(22, 22, 22);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      overflow: hidden;
    }
    .project-hover-cover {
      background-color: rgb(22, 22, 22);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      @include transition(opacity);
      transition-duration: 0.4s;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      filter: blur(0.5px);
      z-index: 3;
      opacity: 0.75;
      transition: opacity 0.025s ease-in-out, transform 0.3s ease;
    }
    a {
      @include flexCenter(row);
      width: 100%;
      height: 100%;
      z-index: 3;
      position: absolute;
      cursor: default;
      .name {
        position: relative;
        font-size: $heading-medium;
        text-transform: uppercase;
        font-family: $font-clashgrotesk;
        font-weight: 500;
        cursor: pointer;
        .underline {
          width: 100%;
          height: 0.25rem;
          background-color: white;
          position: absolute;
          left: 0;
          bottom: 0;
        }
        .count {
          display: flex;
          align-items: center;
          height: 1em;
          font-family: $font-ivypresto;
          font-weight: 400;
          position: absolute;
          font-size: $text-medium;
          font-style: italic;
          transform: translate(-120%, -100%);
        }
      }
    }
  }
}

@media (max-width: $bp-desktop) {
  .featured-work-section {
    .project {
      a {
        .name {
          font-size: var(--heading-medium);
        }
      }
    }
  }
}

@media (max-width: $bp-laptop) {
  .featured-work-section {
    .project {
      a {
        .name {
          font-size: var(--heading-medium);
          .count {
            font-size: $text-medium;
          }
        }
      }
    }
  }
}
