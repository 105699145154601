@use "../util" as *;

.menu {
  @include flexCenter(row);
  gap: 2.5rem;
  background-color: $clr-accent-3;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateY(-100%);
  transition: transform 1s $cubic-transition-2;
  z-index: 5;
  transition-delay: 0.3s;

  aside {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4rem 0;
    padding-top: 18rem;
    .contact {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: $text-small;
      color: $clr-accent-6;
      max-width: max-content;
      opacity: 0;
      transform: translateY(-1rem);
      transition: opacity 0.6s ease-in-out 0.2s, transform 0.7s ease-in-out 0.2s;
    }
    .media-links {
      display: flex;
      gap: 1rem;
      margin-top: 5rem;
      margin-bottom: auto;
      img {
        background-color: $clr-primary;
        display: flex;
        border-radius: 50%;
        transform: translateY(-1.5rem);
        opacity: 0;
        transition: opacity 0.6s ease-in-out 0.6s, transform 0.6s ease-in-out;
      }
      a {
        transition: transform 0.25s ease;
      }
      .link-1 {
        transition-delay: 0.6s;
      }
      .link-2 {
        transition-delay: 0.7s;
      }
      .link-3 {
        transition-delay: 0.8s;
      }
      .link-4 {
        transition-delay: 0.9s;
      }
    }
    .logo-wrapper {
      max-width: max-content;
      display: flex;
      opacity: 0;
      transform: translateY(-1.5rem);
      transition: opacity 0.6s ease-in-out 0.2s, transform 0.6s ease-in-out 0.6s;
      img {
        width: 3.5rem;
        transition: transform 0.3s ease;
      }
    }
  }
  &.show {
    transition-delay: 0s;
    transform: translateY(0);
    .wrapper {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 0.7s ease-in-out, transform 0.7s ease-in-out;
      &.wrap-1 {
        transition-delay: 0.2s;
      }
      &.wrap-2 {
        transition-delay: 0.3s;
      }
      &.wrap-3 {
        transition-delay: 0.4s;
      }
    }
    aside {
      .contact {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.8s ease-in-out 0.6s,
          transform 0.7s ease-in-out 0.6s;
      }
      .media-links {
        img {
          transform: translateY(0);
          opacity: 1;
        }
      }
      .logo-wrapper {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.6s ease-in-out 0.7s,
          transform 0.6s ease-in-out 0.6s;
      }
    }
  }
}

@media (max-width: $bp-desktop) {
  .menu {
    aside {
      .contact {
        font-size: var(--text-small);
      }
    }
  }
}

@media (max-width: $bp-laptop) {
  .menu {
    flex-direction: column;
    aside {
      padding: 0;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      .contact {
        text-align: center;
        order: 2;
        font-size: $text-normal;
      }
      .media-links {
        order: 3;
        margin: 0;
      }
      .logo-wrapper {
        order: 1;
        img {
          width: 3rem;
        }
      }
    }
  }
}

@media (max-width: $bp-tablet) {
  .menu {
    flex-direction: column;
    aside {
      padding: 0;
      align-items: center;
      justify-content: flex-start;
      gap: 2vh;
      margin-top: -5vh;
      .contact {
        text-align: center;
        order: 2;
        font-size: $text-xsmall;
      }
      .media-links {
        order: 3;
        margin: 0;
        img {
          width: 1.75rem;
        }
      }
      .logo-wrapper {
        order: 1;
        img {
          width: 2.5rem;
        }
      }
    }
  }
}
