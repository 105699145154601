@use "../util" as *;

.video-bubble-wrapper {
  width: clamp(270px, 80%, 1000px);
  max-height: 700px;
  border-radius: 20rem;
  overflow: hidden;
  position: relative;
  margin-bottom: 15rem;
  z-index: 2;
  transition: transform 4s $cubic-transition-1;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video-inner-shadow {
    width: 100%;
    height: 100%;
    border-radius: 20rem;
    box-shadow: inset 0 0 50px #000000;
    position: absolute;
  }
}
