@use "../util" as *;

.cursor-circle {
  position: fixed;
  min-width: 0.75rem;
  min-height: 0.75rem;
  border-radius: 50%;
  background-color: $clr-accent-4;
  transform: translate(-120%, -50%);
  z-index: 15;
  transition: all 0.5s ease, left 0.25s $cubic-transition-1,
    top 0.25s $cubic-transition-1, opacity 0.2s ease-in-out;
  mix-blend-mode: difference;
  pointer-events: none;
  &.circle-expand {
    background-color: transparent;
    min-width: 3rem;
    min-height: 3rem;
    border: 1px solid $clr-accent-4;
    transform: translate(-70%, -50%);
  }
  &.hidden {
    opacity: 0;
  }
}

.cursor-circle-change {
  cursor: pointer;
}
